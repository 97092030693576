export const ImageGraphqlMock =
    ` query ContenfulImage{
        contentfulImage(id: {eq: "70ff4c67-ce97-58f8-a3a9-7daa0d30549a"}) {
            title
            image {
                title
                description
                fixed {
                    ...GatsbyContentfulFixed
                }
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`;

export const ContentFulImageMock = {
    "title": "Question image",
    "image": {
        "title": "Unconscious bias",
        "description": "A picture of Unconscious bias",
        "fixed": {
            "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAA3lBMVEUvKyxycHFUUVEjHyBEQEH////+/v6pqKgkICHHx8dEQUImIiNfXF3q6eklISKbmZn09PTv7++GhITz8/MxLS4ZR1pjbXJ7eHnMy8sjHyEgLTQbQFAJh7gGndfB6voQmMw0vfKK2fem4vnA6vp+jpXd8PcCp+YAre8BqeoFmtMDoNyk3PEvsOIKsO9BwfMetvADru8RaIk5dY0cOkgiIiVTUFDh4OCbpKie3/j1+/4oLDFZVleRj5BPTE3+//8qJideW1yQjo/s6+t8enqopqYwLC14dXY4NDVXVFQoJCVvH3iOAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH5QEIDhcuoUKFnQAAAIVJREFUCB0FwbFOAkEABcB5m70zmz0sjKUfYgNW+tPQ0pGgjSY22NFJqLhEjM5EAAmA1JA/lCuGH6jUJJmhJy1nqtuc3GWG9o12kXqfJMmxXD0kSQ6qRZIkRyySJBRf0zR99g69f7y3RqG97t3sYRzZDRRYrkbwNjyBPCebx46yxsuv7ewfZTka8RLCbYAAAAAASUVORK5CYII=",
            "width": 400,
            "height": 200,
            "src": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=400&q=50",
            "srcSet": ""
        },
        "fluid": {
            "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAA3lBMVEUvKyxycHFUUVEjHyBEQEH////+/v6pqKgkICHHx8dEQUImIiNfXF3q6eklISKbmZn09PTv7++GhITz8/MxLS4ZR1pjbXJ7eHnMy8sjHyEgLTQbQFAJh7gGndfB6voQmMw0vfKK2fem4vnA6vp+jpXd8PcCp+YAre8BqeoFmtMDoNyk3PEvsOIKsO9BwfMetvADru8RaIk5dY0cOkgiIiVTUFDh4OCbpKie3/j1+/4oLDFZVleRj5BPTE3+//8qJideW1yQjo/s6+t8enqopqYwLC14dXY4NDVXVFQoJCVvH3iOAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH5QEIDhcuoUKFnQAAAIVJREFUCB0FwbFOAkEABcB5m70zmz0sjKUfYgNW+tPQ0pGgjSY22NFJqLhEjM5EAAmA1JA/lCuGH6jUJJmhJy1nqtuc3GWG9o12kXqfJMmxXD0kSQ6qRZIkRyySJBRf0zR99g69f7y3RqG97t3sYRzZDRRYrkbwNjyBPCebx46yxsuv7ewfZTka8RLCbYAAAAAASUVORK5CYII=",
            "aspectRatio": 2,
            "src": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=800&q=50",
            "srcSet": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=200&h=100&q=50 200w,\n//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=318&h=159&q=50 318w",
            "sizes": "(max-width: 800px) 100vw, 800px"
        }
    }
}

export const VideoGraphqlMock =
    ` query ContentfulCourse{
        contentfulVideo(id: {eq: "58e511fb-5d3a-584b-8350-725817355cec"}) {
            title
            video {
                title
                description
                file {
                    url
                    contentType
                }
            }
        }
    }`;
    

export const ContentfulVideoMock = {
    "title": "Understanding Unconscious Bias Video",
    "video": {
        "title": "Understanding Unconscious Bias",
        "description": "This animation introduces the key concepts of unconscious bias.  It forms part of the Royal Society’s efforts to ensure that all those who serve on Royal Society selection and appointment panels are aware of differences in how candidates may present themselves, how to recognise bias in yourself and others, how to recognise inappropriate advocacy or unreasoned judgement. You can find out more about unconscious bias and download a briefing which includes current academic research at www.royalsociety.org/diversity.",
        "file": {
            "url": "//videos.ctfassets.net/n2lautx7332t/2Mkp14lpLSZWOm70QZLt5F/8a619045ef93560b1fb402ee6b31b8aa/UnconsciousBias.webm",
            "contentType": "video/webm"
        }
    }
}

export const RTAGraphqlMock =
    ` query ContentfulCourse{
        contentfulCourse(id: {eq: "82d2bd6f-f1a7-53aa-8c95-c2d1dcba5d58"}) {
            overview {
              raw
              references {
                ... on ContentfulAsset {
                  title
                  description
                  contentful_id
                  file {
                    url
                    contentType
                  }
                  fixed {
                    ...GatsbyContentfulFixed
                  }
                  fluid {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
          }
    }`;
    


export const ContentfulRichTextAreaMock = {

    "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"This is the introduction to the course: \",\"nodeType\":\"text\"}],\"nodeType\":\"heading-2\"},{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"An understanding of what unconscious bias is \",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"list-item\"},{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"Ability to identify unconscious bias in decision making and behaviour \",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"list-item\"}],\"nodeType\":\"ordered-list\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"2EL7SGEyz5SwJ16o5ujF66\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}",
    "references": [
        {
            "__typename": "ContentfulAsset",
            "title": "sample video",
            "description": "",
            "contentful_id": "2EL7SGEyz5SwJ16o5ujF66",
            "file": {
                "url": "//videos.ctfassets.net/n2lautx7332t/2EL7SGEyz5SwJ16o5ujF66/d2cb8c93474066c8b7113c86eeb1fd34/SampleVideo_1280x720_5mb.mp4",
                "contentType": "video/mp4"
            },
            "fixed": null,
            "fluid": null
        },
        {
            "__typename": "ContentfulAsset",
            "title": "Unconscious bias",
            "description": "A picture of Unconscious bias",
            "contentful_id": "5dNNr8r1pp8OgY2cJPNAez",
            "file": {
                "url": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png",
                "contentType": "image/png"
            },
            "fixed": {
                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAA3lBMVEUvKyxycHFUUVEjHyBEQEH////+/v6pqKgkICHHx8dEQUImIiNfXF3q6eklISKbmZn09PTv7++GhITz8/MxLS4ZR1pjbXJ7eHnMy8sjHyEgLTQbQFAJh7gGndfB6voQmMw0vfKK2fem4vnA6vp+jpXd8PcCp+YAre8BqeoFmtMDoNyk3PEvsOIKsO9BwfMetvADru8RaIk5dY0cOkgiIiVTUFDh4OCbpKie3/j1+/4oLDFZVleRj5BPTE3+//8qJideW1yQjo/s6+t8enqopqYwLC14dXY4NDVXVFQoJCVvH3iOAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH5QEIDhcuoUKFnQAAAIVJREFUCB0FwbFOAkEABcB5m70zmz0sjKUfYgNW+tPQ0pGgjSY22NFJqLhEjM5EAAmA1JA/lCuGH6jUJJmhJy1nqtuc3GWG9o12kXqfJMmxXD0kSQ6qRZIkRyySJBRf0zR99g69f7y3RqG97t3sYRzZDRRYrkbwNjyBPCebx46yxsuv7ewfZTka8RLCbYAAAAAASUVORK5CYII=",
                "width": 400,
                "height": 200,
                "src": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=400&q=50",
                "srcSet": ""
            },
            "fluid": {
                "base64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAA3lBMVEUvKyxycHFUUVEjHyBEQEH////+/v6pqKgkICHHx8dEQUImIiNfXF3q6eklISKbmZn09PTv7++GhITz8/MxLS4ZR1pjbXJ7eHnMy8sjHyEgLTQbQFAJh7gGndfB6voQmMw0vfKK2fem4vnA6vp+jpXd8PcCp+YAre8BqeoFmtMDoNyk3PEvsOIKsO9BwfMetvADru8RaIk5dY0cOkgiIiVTUFDh4OCbpKie3/j1+/4oLDFZVleRj5BPTE3+//8qJideW1yQjo/s6+t8enqopqYwLC14dXY4NDVXVFQoJCVvH3iOAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH5QEIDhcuoUKFnQAAAIVJREFUCB0FwbFOAkEABcB5m70zmz0sjKUfYgNW+tPQ0pGgjSY22NFJqLhEjM5EAAmA1JA/lCuGH6jUJJmhJy1nqtuc3GWG9o12kXqfJMmxXD0kSQ6qRZIkRyySJBRf0zR99g69f7y3RqG97t3sYRzZDRRYrkbwNjyBPCebx46yxsuv7ewfZTka8RLCbYAAAAAASUVORK5CYII=",
                "aspectRatio": 2,
                "src": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=800&q=50",
                "srcSet": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=200&h=100&q=50 200w,\n//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png?w=318&h=159&q=50 318w",
                "sizes": "(max-width: 800px) 100vw, 800px"
            }
        }
    ]
}
