import React from "react";
import { makeStyles, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Container } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';

import { HeaderText } from "src/components/labels";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import ContentfulVideo, { ContentfulVideoType } from "src/contentful/contentful-video";
import ContentfulImage, { ContentfulImageType } from "src/contentful/contentful-image";
import { ContentFulImageMock, ContentfulVideoMock, ContentfulRichTextAreaMock, ImageGraphqlMock, VideoGraphqlMock, RTAGraphqlMock } from 'src/mocks/contentful-mocks';
import { DragAndDrop, Likert, MultipleChoice, MultiRadioSelect, MultiSelect } from "src/components/assessments";
import { ContentfulDragAndDrop, ContentfulLikertAssessment, ContentfulMultipleChoiceQuestion, ContentfulMultiRadioSelectAssessment, ContentfulMultiSelectAssessment, } from "types/graphql-types";
import { ContentfulDragAndDropAssessmentMock, ContentfulMultipleChoiceQuestionMock, ContentfulMultiRadioAssessmentMock, ContentfulMultiSelectAssessmentMock, DragAndDropAssessmentGraphqlMock, MultipleChoiceQuestionGraphqlMock, MultiRadioAssessmentGraphqlMock, MultiSelectAssessmentGraphqlMock } from "src/mocks/contentful-assessment-mocks";
import { ContentfulLikertAssessmentMock, LikertScaleGraphqlMock } from "src/mocks/contentful-survey-mocks";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CodeBlock, dracula } from "react-code-blocks";
import { CustomLayout } from "src/components/layout";
import { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "0 auto",
        width: "70vw",
        [theme.breakpoints.down('sm')]: {
            width: "90vw",
        },
    },
    codeBlock: {
        minWidth: "100%",
        fontSize: "0.6rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8rem",
        },
    },
    accordian: {

    }
}));

interface IProps {
}

export default function Gallery({ }: IProps) {
    const classes = useStyles();
    const headerProps: IHeaderProps = {
        type: headerTypes.logo
    }

    return (
        <CustomLayout
            headerProps={headerProps}
        >
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item lg={12}><Typography paragraph variant="h2">Components</Typography></Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion className={classes.accordian} elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Rich Text Area Component</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <CodeBlock
                                                text={RTAGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <ContentfulRichText {...ContentfulRichTextAreaMock as ContentfulRichTextType} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Video Component</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <CodeBlock
                                                text={VideoGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <ContentfulVideo {...ContentfulVideoMock as ContentfulVideoType} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Image Component</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <CodeBlock
                                                text={ImageGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <ContentfulImage {...ContentFulImageMock as ContentfulImageType} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Assessment */}
                <Grid container spacing={2}>
                    <Grid item lg={12}><Typography paragraph variant="h2">Assessments</Typography></Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Multi Radio Select Assessment</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <CodeBlock
                                                text={MultiRadioAssessmentGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <MultiRadioSelect headerProps={headerProps} handleNextSection={() => { }} data={ContentfulMultiRadioAssessmentMock as unknown as ContentfulMultiRadioSelectAssessment} setTitle={() => { }} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Multi Select Assessment</Typography></AccordionSummary>
                            <AccordionDetails>   <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={12} xs={12}>

                                        <CodeBlock
                                            text={MultiSelectAssessmentGraphqlMock}
                                            language={"graphql"}
                                            showLineNumbers={true}
                                            theme={dracula}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={12} xs={12}>
                                        <MultiSelect headerProps={headerProps} handleNextSection={() => { }} data={ContentfulMultiSelectAssessmentMock as ContentfulMultiSelectAssessment} setTitle={() => { }} />
                                    </Grid>
                                </Grid>
                            </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Drag and Drop Assessment</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <CodeBlock
                                                text={DragAndDropAssessmentGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <DragAndDrop headerProps={headerProps} handleNextSection={() => { }} data={ContentfulDragAndDropAssessmentMock as unknown as ContentfulDragAndDrop} setTitle={() => { }} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* Survey */}
                <Grid container spacing={2}>
                    <Grid item lg={12}><Typography paragraph variant="h2">Survey Components</Typography></Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Likert Scale</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>

                                            <CodeBlock
                                                text={LikertScaleGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <Likert section="gallery" headerProps={headerProps} handleNextSection={() => { }} data={ContentfulLikertAssessmentMock as unknown as ContentfulLikertAssessment} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <Accordion elevation={3}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header"><Typography>Multiple Choice Assessment</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={7} style={{ padding: "20px" }} className={classes.codeBlock}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <CodeBlock
                                                text={MultipleChoiceQuestionGraphqlMock}
                                                language={"graphql"}
                                                showLineNumbers={true}
                                                theme={dracula}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={12} xs={12}>
                                            <MultipleChoice section="gallery" headerProps={headerProps} handleNextSection={() => { }} data={ContentfulMultipleChoiceQuestionMock as unknown as ContentfulMultipleChoiceQuestion} setTitle={() => { }} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </div>
        </CustomLayout>
    );
}
