

export const MultiRadioAssessmentGraphqlMock =
    ` query ContentfulMultiRadioSelectAssessment{
    contentfulMultiRadioSelectAssessment(id: {eq: "82d2bd6f-f1a7-53aa-8c95-c2d1dcba5d58"}) {
        id
        feedback {
          raw
        }
        firstOptionContainer {
          id
          image {
            id
          }
          title
          text
          list {
            id
            text
            title
            correctAnswerReference {
              id
            }
          }
        }
        overview {
          raw
        }
        secondOptionContainer {
          id
          image {
            id
          }
          text
          title
          list {
            id
            text
            title
            correctAnswerReference {
              id
            }
          }
        }
      }
    }
}`;


export const ContentfulMultiRadioAssessmentMock = {
    "id": "7e75bebe-d503-511e-bcc1-242fd942f40a",
    "feedback": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"2EL7SGEyz5SwJ16o5ujF66\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "firstOptionContainer": {
        "id": "7ec5611b-d041-5dce-b2c3-8c51a822ad69",
        "image": null,
        "title": "Dummy option container 2",
        "text": "Male",
        "list": [
          {
              "id": "eeff9824-942c-5c3a-8efe-d4e29eec01ab",
              "text": "Suit",
              "title": "Dummy answer container",
          }
      ],
    },
    "overview": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"2EL7SGEyz5SwJ16o5ujF66\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
   
    "secondOptionContainer": {
        "id": "4a515220-0eef-59ec-aad6-6700ebc67d9d",
        "image": {
            "id": "97ff3e44-389f-5d43-8d71-472a937c09f5"
        },
        "text": "Female ",
        "title": "Dummy Assessment options",
        "list": [
          {
              "id": "16026000-b71e-5b29-9bb2-e1dc981ee4d6",
              "text": "Dress",
              "title": "Dummy answer 1",
          }
      ],
    }
}


export const MultiSelectAssessmentGraphqlMock =
    ` query ContentfulMultiSelectAssessment{
        contentfulMultiSelectAssessment(id: {eq: "82d2bd6f-f1a7-53aa-8c95-c2d1dcba5d58"}) {
            id
          feedback {
            raw
          }
          overview {
            raw
          }
          title
          options {
            id
            correctAnswer
            title
          }
    }
}`;



export const ContentfulMultiSelectAssessmentMock = {
    "id": "01dffc40-50ea-575f-b2a5-78df3f00ad6c",
    "feedback": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"This is the feedback\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"44ydNR4LF8rLxGbeLcQcCP\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "overview": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"This is the overview \",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"44ydNR4LF8rLxGbeLcQcCP\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"
    },
    "title": "Dummy Multi Select Assessment",
    "options": [
        {
            "id": "9c8f4a2c-d260-5852-9232-49ed006ef624",
            "correctAnswer": true,
            "title": "New Dummy Questions"
        },
        {
            "id": "e80b2817-589f-5d72-97fc-ffac2c289f39",
            "correctAnswer": true,
            "title": "Dummy multi select"
        },
        {
            "id": "08af1f4c-b6ee-593c-a61f-aafa534c6102",
            "correctAnswer": false,
            "title": "Dummy last multi select"
        }
    ]
}

export const MultipleChoiceQuestionGraphqlMock =
    ` 
    query ContentfulMultipleChoiceQuestion {
        contentfulMultipleChoiceQuestion {
          correctDebrief {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                title
                description
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                    ...GatsbyContentfulFixed
                }
                fluid {
                    ...GatsbyContentfulFluid
                }
              }
            }
          }
          wrongDebrief {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                title
                description
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                    ...GatsbyContentfulFixed
                }
                fluid {
                    ...GatsbyContentfulFluid
                }
              }
            }
          }
          title
          options {
            id
            label
            title
            correctAnswer
          }
          question {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                title
                description
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                    ...GatsbyContentfulFixed
                }
                fluid {
                    ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
`;


export const ContentfulMultipleChoiceQuestionMock = {
    "correctDebrief": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"Right answer\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}",
        "references": []
    },
    "wrongDebrief": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"WRong answer\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}",
        "references": []
    },
    "title": "Multiple Choice 1",
    "options": [
        {
            "id": "de9bf7a6-d441-50a5-9c59-9c0a6190f3ad",
            "label": "True",
            "title": "True",
            "correctAnswer": false
        },
        {
            "id": "a3a0661e-4bf5-5c7e-9c01-242354b56d0e",
            "label": "Attitude or stereotype that we do not know we have",
            "title": "Attitude or stereotype that we do not know we have",
            "correctAnswer": true
        },
        {
            "id": "7e4ba05d-2675-5973-ad69-028e4bc54811",
            "label": "Attitudes and stereotypes that we can self-correct",
            "title": "Attitudes and stereotypes that we can self-correct",
            "correctAnswer": false
        },
        {
            "id": "1908f7e8-e9c5-53de-8497-88f97401d45b",
            "label": "Attitudes and stereotypes that we can self-correct",
            "title": "Attitudes and stereotypes that we can self-correct",
            "correctAnswer": false
        },
        {
            "id": "6227bc08-8b95-5bb9-963a-b2048ff8b7c9",
            "label": "Attitudes and stereotypes that we know we have",
            "title": "Attitudes and stereotypes that we know we have",
            "correctAnswer": false
        },
        {
            "id": "85d40363-beb2-52ac-9341-15b2583c6561",
            "label": "Attitudes and stereotypes that we know we have",
            "title": "Attitudes and stereotypes that we know we have",
            "correctAnswer": false
        }
    ],
    "question": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"How are you doing\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}",
        "references": []
    }
}



export const DragAndDropAssessmentGraphqlMock =
    ` query ContentfulDragAndDrop {
        contentfulDragAndDrop {
          id
          title
          feedback {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                title
                description
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
          overview {
            raw
            references {
              __typename
              ... on ContentfulAsset {
                title
                description
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
          firstContainer {
            title
            text
            id
            image {
              id
              contentful_id
              file {
                url
                contentType
              }
              fixed {
                ...GatsbyContentfulFixed
              }
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
          secondContainer {
            text
            title
            id
            image {
              id
              contentful_id
              file {
                url
                contentType
              }
              fixed {
                ...GatsbyContentfulFixed
              }
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
          list {
            title
            text
            correctAnswer {
              id
            }
            image {
              id
              contentful_id
              file {
                url
                contentType
              }
              fixed {
                ...GatsbyContentfulFixed
              }
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
`;


export const ContentfulDragAndDropAssessmentMock = {
    "id": "68ccd01c-a348-58ad-9cf7-3fafc4981707",
    "title": "Dummy drag and Drop",
    "feedback": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"2EL7SGEyz5SwJ16o5ujF66\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"This is feedback page.\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}",
        "references": [
            {
                "__typename": "ContentfulAsset",
                "title": "Unconscious bias",
                "description": "A picture of Unconscious bias",
                "contentful_id": "5dNNr8r1pp8OgY2cJPNAez",
                "file": {
                    "url": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png",
                    "contentType": "image/png"
                }
            },
            {
                "__typename": "ContentfulAsset",
                "title": "sample video",
                "description": "",
                "contentful_id": "2EL7SGEyz5SwJ16o5ujF66",
                "file": {
                    "url": "//videos.ctfassets.net/n2lautx7332t/2EL7SGEyz5SwJ16o5ujF66/d2cb8c93474066c8b7113c86eeb1fd34/SampleVideo_1280x720_5mb.mp4",
                    "contentType": "video/mp4"
                }
            }
        ]
    },
    "overview": {
        "raw": "{\"data\":{},\"content\":[{\"data\":{\"target\":{\"sys\":{\"id\":\"5dNNr8r1pp8OgY2cJPNAez\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{\"target\":{\"sys\":{\"id\":\"2EL7SGEyz5SwJ16o5ujF66\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[],\"nodeType\":\"embedded-asset-block\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"},{\"data\":{},\"content\":[{\"data\":{},\"marks\":[],\"value\":\"This is a dummy drag and drop\",\"nodeType\":\"text\"}],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}",
        "references": [
            {
                "__typename": "ContentfulAsset",
                "title": "Unconscious bias",
                "description": "A picture of Unconscious bias",
                "contentful_id": "5dNNr8r1pp8OgY2cJPNAez",
                "file": {
                    "url": "//images.ctfassets.net/n2lautx7332t/5dNNr8r1pp8OgY2cJPNAez/00e347cce398f712f5ad20fc11e5cfb2/Bias.png",
                    "contentType": "image/png"
                }
            },
            {
                "__typename": "ContentfulAsset",
                "title": "sample video",
                "description": "",
                "contentful_id": "2EL7SGEyz5SwJ16o5ujF66",
                "file": {
                    "url": "//videos.ctfassets.net/n2lautx7332t/2EL7SGEyz5SwJ16o5ujF66/d2cb8c93474066c8b7113c86eeb1fd34/SampleVideo_1280x720_5mb.mp4",
                    "contentType": "video/mp4"
                }
            }
        ]
    },
    "firstContainer": {
        "title": "Dummy Container 1",
        "text": "Male",
        "id": "81922008-0b1d-55b1-bc97-5b35b7997388",
        "image": null
    },
    "secondContainer": {
        "text": "Female",
        "title": "Dummy Container 2",
        "id": "40c12f86-c29e-555e-a1df-42f707c12997",
        "image": null
    },
    "list": [
        {
            "title": "Dummy Drag and Drop list",
            "text": "First item",
            "correctAnswer": {
                "id": "81922008-0b1d-55b1-bc97-5b35b7997388"
            },
            "image": null
        },
        {
            "title": "Second Drag and drop",
            "text": "Item 2",
            "correctAnswer": {
                "id": "40c12f86-c29e-555e-a1df-42f707c12997"
            },
            "image": null
        },
        {
            "title": "Third Dummy drag and drop",
            "text": null,
            "correctAnswer": {
                "id": "40c12f86-c29e-555e-a1df-42f707c12997"
            },
            "image": {
                "id": "d1a08474-2a0a-558a-9cb1-547bc5c40f7b",
                "contentful_id": "7synSdHrIoNhd58bFgsOOQ",
                "file": {
                    "url": "//images.ctfassets.net/n2lautx7332t/7synSdHrIoNhd58bFgsOOQ/bc15f531a258ebfbf5919853a572fa03/logo.svg",
                    "contentType": "image/svg+xml"
                }
            }
        }
    ]
}