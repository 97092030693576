import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ContentfulAsset as ContentfulAssetType } from "types/graphql-types";
import ContentfulAsset from "src/contentful/contentful-asset";

const useStyles = makeStyles({
  root: {
  },
});

export type ContentfulImageType = {
  title?: string,
  image: ContentfulAssetType
}

export default function ContentfulImage({ title, image }: ContentfulImageType) {
  const classes = useStyles();
  return (
    <div className={classes.root}><ContentfulAsset {...image} /></div>
  );
}
