

export const LikertScaleGraphqlMock =
`query ContenfulLikertAssessment {
    contentfulLikertAssessment {
      id
      title
      startingLabel
      endingLabel
      question {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            title
            description
            contentful_id
            file {
              url
              contentType
            }
            fixed {
              ...GatsbyContentfulFixed
            }
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      startingScale
      endingScale
    }
  }
  `;


export const ContentfulLikertAssessmentMock = {
    "id": "6098eb3a-820b-5724-b57e-dc2f7defcf7a",
    "title": "How Biased are you towards Skinny people?",
    "startingLabel": "Not",
    "endingLabel": "Very",
    "question": {
        "raw": "{\"nodeType\":\"document\",\"data\":{},\"content\":[{\"nodeType\":\"heading-6\",\"content\":[{\"nodeType\":\"text\",\"value\":\"How Biased are you towards skinny people?\",\"marks\":[],\"data\":{}}],\"data\":{}},{\"nodeType\":\"paragraph\",\"content\":[{\"nodeType\":\"text\",\"value\":\"\",\"marks\":[],\"data\":{}}],\"data\":{}}]}",
        "references": []
    },
    "startingScale": 0,
    "endingScale": 10
}